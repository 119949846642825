import * as React from "react";
import {
  BookOpenIcon,
  HeartIcon,
  SupportIcon,
  SparklesIcon,
  EmojiHappyIcon,
  GlobeIcon,
} from "@heroicons/react/outline";

const Activities = () => {
  const activities = [
    {
      title: "French Classes",
      icon: BookOpenIcon,
    },
    {
      title: "Yoga Training",
      icon: HeartIcon,
    },
    {
      title: "Sport Ball",
      icon: SupportIcon,
    },
    {
      title: "Magic Show",
      icon: SparklesIcon,
    },
    {
      title: "Teeth Checking",
      icon: EmojiHappyIcon,
    },
    {
      title: "Field Trip",
      icon: GlobeIcon,
    },
  ];
  return (
    <div className="max-w-screen-xl mx-auto py-12 lg:py-24">
      <div className="px-4">
        <h2 className="text-greenDark text-4xl font-bold font-quick tracking-tight text-center">
          Our Fun Activities
        </h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-y-5 lg:gap-y-8 mt-10 lg:mt-14">
          {activities.map((activity) => (
            <div key={activity.title} className="flex items-center space-x-5">
              <div className="flex-shrink-0">
                <activity.icon
                  className="h-[65px] w-[65px] text-green bg-[#DCE7E4] p-[20px] rounded-full"
                  aria-hidden="true"
                />
              </div>
              <div className="flex-1 min-w-0">
                <h6 className="text-md font-bold font-quick text-[18px] text-greenDark">
                  {activity.title}
                </h6>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Activities;
