import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";

const Offer = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "offer.jpeg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  return (
    <div>
      <div className="max-w-full items-center mx-auto lg:grid lg:grid-cols-2">
        <div className="px-12 lg:px-44 py-12 text-center">
          <h2 className="text-greenDark text-4xl font-bold font-quick tracking-tight text-center">
            We Offer …
          </h2>
          <p className="mt-5 font-light text-dark">
            Authentic Montessori Education during the academic year for children
            between the ages of 2.5 to 6 years old. Toddler Program based on
            Early Childhood Education for children between the ages of 1.5 to
            2.5 (up to 3) years old.
            <br />
            Lunch (For Full Day Program) plus morning and afternoon Snacks.
            <br />
            Extended hours (8 to 9 a.m. & 4 to 6 p.m.).
            <br />A well equipped outside playground as well as a large indoor
            gymnasium.
          </p>
        </div>

        <div className="">
          <GatsbyImage
            className="h-[300px] lg:h-[500px] w-full"
            image={data.file.childImageSharp.gatsbyImageData}
            alt="Team Meeting"
          />
        </div>
      </div>
    </div>
  );
};

export default Offer;
