import * as React from "react";
import Activities from "../components/home/activities";
import Cta from "../components/home/cta";
import Hero from "../components/home/hero";
import Offer from "../components/home/offer";
import Purpose from "../components/home/purpose";
import Stats from "../components/home/stats";
import Tabs from "../components/home/tabs";
import Layout from "../components/layout";

const IndexPage = () => {
  return (
    <Layout pageTitle="Home - Forest Grove Montessori Pre-School">
      <Hero />
      <Purpose />
      <Tabs />
      <Offer />
      <Activities />
      <Cta />
      <Stats />
    </Layout>
  );
};

export default IndexPage;
