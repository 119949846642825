import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, Link, useStaticQuery } from "gatsby";

const Purpose = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "purpose-1.jpeg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      image2: file(relativePath: { eq: "purpose-2.jpeg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  return (
    <div className="px-4 py-12 lg:py-24">
      <div className="max-w-screen-xl items-center mx-auto lg:grid lg:grid-cols-2">
        <div className="">
          <GatsbyImage
            className="h-full w-full"
            image={data.image1.childImageSharp.gatsbyImageData}
            alt="Purpose One"
          />
        </div>
        <div className="px-10 py-10 text-center">
          <h2 className="text-greenDark text-4xl font-bold font-quick tracking-tight">
            School Philosophy
          </h2>
          <p className="mt-5 font-light text-dark">
            Our mission is to create a happy and nurturing environment for
            children between the ages of 18 months to 6 years old, which is
            conducive to the development of their social, intellectual,
            academic, physical and artistic skills
          </p>
          <Link
            to="/contact"
            className="mt-5 inline-flex items-center px-8 py-4 font-medium rounded-full shadow text-white bg-greenDark hover:bg-cyan"
          >
            Schedule a Tour
          </Link>
        </div>
      </div>
      <div className="max-w-screen-xl items-center mx-auto lg:grid lg:grid-cols-2">
        <div className="px-10 py-10 text-center">
          <h2 className="text-greenDark text-4xl font-bold font-quick tracking-tight">
            The Purpose of Montessori Education
          </h2>
          <p className="mt-5 font-light text-dark">
            The Montessori method of early childhood education is based on
            cultivating each child’s natural desire to learn and experiencing
            the excitement of learning by his/her own choice using Montessori
            materials.
          </p>
          <Link
            to="/purpose"
            className="mt-5 inline-flex items-center px-8 py-4 font-medium rounded-full shadow text-white bg-greenDark hover:bg-cyan"
          >
            More Information
          </Link>
        </div>
        <div className="">
          <GatsbyImage
            className="h-full w-full"
            image={data.image2.childImageSharp.gatsbyImageData}
            alt="Purpose One"
          />
        </div>
      </div>
    </div>
  );
};

export default Purpose;
