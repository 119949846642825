import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, Link, useStaticQuery } from "gatsby";

const Cta = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "cta-bg.jpeg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  return (
    <div className="max-w-full mx-auto">
      <div className="relative">
        <div className="absolute inset-0">
          <GatsbyImage
            className="h-full w-full object-cover"
            image={data.file.childImageSharp.gatsbyImageData}
            alt="Background"
          />
          <div className="absolute inset-0 bg-[rgba(0,0,0,0.4)] mix-blend-multiply" />
        </div>
        <div className="max-w-screen-xl m-auto relative px-4 py-24 lg:py-40 text-center">
          <h1 className="font-quick font-bold text-white text-2xl sm:text-3xl lg:text-4xl">
            What About Admission?
          </h1>
          <Link
            to="/admission"
            className="mt-10 inline-flex items-center px-8 py-4 font-medium rounded-full shadow text-white bg-cyan hover:bg-pink"
          >
            More Information
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Cta;
