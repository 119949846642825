import * as React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "hero.jpeg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  return (
    <div>
      <div className="-z-50 relative">
        <div className="max-w-full mx-auto">
          <div className="relative">
            <div className="absolute inset-0">
              <GatsbyImage
                className="h-full w-full object-cover"
                image={data.file.childImageSharp.gatsbyImageData}
                alt="Background"
              />
              <div className="absolute inset-0 bg-[rgba(0,0,0,0.5)] mix-blend-multiply" />
            </div>
            <div className="max-w-screen-xl m-auto relative px-4 pt-[25vh] pb-[25vh] md:pt-[33vh] md:pb-[40vh]">
              <h1 className="text-white font-light text-3xl sm:text-4xl lg:text-5xl">
                Forest Grove Montessori Pre-School <br /> in North York
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="lg:mt-[-100px] z-50 relative max-w-screen-xl mx-auto lg:grid lg:grid-cols-2 lg:gap-5 lg:space-y-0">
        <div className="px-16 lg:px-32 py-10 bg-green text-center">
          <h2 className="text-white text-2xl font-bold font-quick tracking-tight">
            Location
          </h2>
          <p className="mt-3 text-base font-light text-white">
            3 Forest Grove Drive, North York, Ontario M2K-1Z4 Major Intersection
            (South of Finch Avenue, East of Bayview Avenue)
          </p>
          <div className="mt-4">
            <Link
              to="/contact"
              className="text-base font-medium text-gray-300 hover:text-white border-b border-white hover:border-0"
            >
              Get Direction
            </Link>
          </div>
        </div>
        <div className="px-16 lg:px-32 py-10 bg-green text-center">
          <h2 className="text-white text-2xl font-bold font-quick tracking-tight">
            Hours Of Operation
          </h2>
          <p className="mt-3 text-base font-light text-white">
            Open from 8AM to 6PM, Monday through Friday. We Also offer Extended
            hours (8 to 9 a.m. & 4 to 6 p.m.)
          </p>
        </div>
      </div>
    </div>
  )
}

export default Hero
