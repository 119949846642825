import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Tab } from "@headlessui/react";
import { graphql, useStaticQuery } from "gatsby";

const Tabs = () => {
  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "tabs-bg.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      tab1: file(relativePath: { eq: "tab-1.jpeg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      tab2: file(relativePath: { eq: "tab-2.jpeg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      tab3: file(relativePath: { eq: "tab-3.jpeg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      tab4: file(relativePath: { eq: "tab-4.jpeg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      tab5: file(relativePath: { eq: "tab-5.jpeg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  const titles = [
    { id: 1, name: "Practical Life" },
    { id: 2, name: "Sensorial" },
    { id: 3, name: "Language" },
    { id: 4, name: "Mathematics" },
    { id: 5, name: "Cultural" },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div>
      <div className="max-w-full mx-auto">
        <div className="relative">
          <div className="absolute inset-0">
            <GatsbyImage
              className="h-full w-full object-cover"
              image={data.backgroundImage.childImageSharp.gatsbyImageData}
              alt="Background"
            />
            <div className="absolute inset-0" />
          </div>
          <div className="max-w-screen-xl m-auto relative px-4 py-12 lg:py-24">
            <h2 className="text-greenDark text-4xl font-bold font-quick tracking-tight text-center">
              Key Areas of Learning in The Montessori Curriculum
            </h2>
            <div className="mt-10">
              <Tab.Group>
                <Tab.List className="w-full m-auto grid grid-cols-1 lg:grid-cols-5">
                  {titles.map((item) => (
                    <Tab
                      key={item.key}
                      className={({ selected }) =>
                        classNames(
                          "w-full bg-[rgba(255,255,255,0.7)] py-4 lg:py-8  text-greenDark border border-greenDark lg:border-r-0 lg:last:border lg:rounded-tl-lg lg:rounded-tr-3xl",
                          "focus:outline-none",
                          selected ? "bg-white lg:border-b-0" : ""
                        )
                      }
                    >
                      {item.name}
                    </Tab>
                  ))}
                </Tab.List>
                <Tab.Panels className="bg-white border border-t-0 border-b-4 border-greenDark">
                  <Tab.Panel className="grid grid-cols-1 gap-12 lg:grid-cols-12 p-12">
                    <div className="lg:col-span-9">
                      <div class="border-2 border-cyan w-[8%]"></div>
                      <h2 className="text-greenDark text-2xl font-bold font-quick tracking-tight pt-5">
                        Practical Life Activities
                      </h2>
                      <p className="mt-5 font-light text-dark">
                        Practical Life Exercises are those with which the child
                        can learn how to do living activities in purposeful way,
                        with the help of Practical Life Exercises such as
                        scooping, pouring, sweeping, sewing and gardening our
                        students master essential skills such as responsibility,
                        confidence, and competence which finally empower them to
                        become independent. Practical Life Exercises also aid
                        them to grow and develop their concentration and will in
                        turn also help them to better develop an orderly way of
                        thinking. It indirectly prepares them for reading and
                        writing.
                      </p>
                    </div>
                    <div className="lg:col-span-3">
                      <GatsbyImage
                        className="lg:h-[325px] lg:w-[100%]"
                        image={data.tab1.childImageSharp.gatsbyImageData}
                        alt="Tab1"
                      />
                    </div>
                  </Tab.Panel>
                  <Tab.Panel className="grid grid-cols-1 gap-12 lg:grid-cols-12 p-12">
                    <div className="lg:col-span-9">
                      <div class="border-2 border-cyan w-[8%]"></div>
                      <h2 className="text-greenDark text-2xl font-bold font-quick tracking-tight pt-5">
                        Sensorial Activities
                      </h2>
                      <p className="mt-5 font-light text-dark">
                        Sensorial activities are designed to help children to
                        concentrate on the reinforcement of their intellectual
                        senses which develops the ability to observe and compare
                        with precision.The purpose of these activities is to
                        guide children to acquire clear information and then
                        make classification in their environments which finally
                        leads them to adapting to their environment. In our
                        centre we provide Montessori material to better help our
                        students practice these activities with the focus on
                        visual and taste perception, tactile impressions,
                        stereognostic and olfactory senses.
                      </p>
                    </div>
                    <div className="lg:col-span-3">
                      <GatsbyImage
                        className="lg:h-[325px] lg:w-[100%]"
                        image={data.tab2.childImageSharp.gatsbyImageData}
                        alt="Tab2"
                      />
                    </div>
                  </Tab.Panel>
                  <Tab.Panel className="grid grid-cols-1 gap-12 lg:grid-cols-12 p-12">
                    <div className="lg:col-span-9">
                      <div class="border-2 border-cyan w-[8%]"></div>
                      <h2 className="text-greenDark text-2xl font-bold font-quick tracking-tight pt-5">
                        Language Activities
                      </h2>
                      <p className="mt-5 font-light text-dark">
                        Language material in Montessori education environment
                        help children to enhance their vocabulary and gain the
                        joy of being spoken to and being listened to. In our
                        centrer we educate children with different language
                        activities such as sandpaper and movable alphabets to
                        empower children with skills such as learning phonetic
                        sounds , composing their own written work and read
                        others so they can finally share their thoughts,
                        opinions and feelings.
                      </p>
                    </div>
                    <div className="lg:col-span-3">
                      <GatsbyImage
                        className="lg:h-[325px] lg:w-[100%]"
                        image={data.tab3.childImageSharp.gatsbyImageData}
                        alt="Tab3"
                      />
                    </div>
                  </Tab.Panel>
                  <Tab.Panel className="grid grid-cols-1 gap-12 lg:grid-cols-12 p-12">
                    <div className="lg:col-span-9">
                      <div class="border-2 border-cyan w-[8%]"></div>
                      <h2 className="text-greenDark text-2xl font-bold font-quick tracking-tight pt-5">
                        Math Activities
                      </h2>
                      <p className="mt-5 font-light text-dark">
                        Passing sensorial activities have prepared children to
                        pursue more complicated math functions such as number
                        recognition, counting and sequencing numbers.Students in
                        our centre have proven records of accomplishing high
                        levels in math prior to elementary school. You can
                        review some of their works in our website photo gallery
                        which contain formal introduction to mathematics, and
                        the introduction of abstract mathematical concepts such
                        as decimal system and mathematical operations such as
                        addition, subtraction, multiplication and division.
                      </p>
                    </div>
                    <div className="lg:col-span-3">
                      <GatsbyImage
                        className="lg:h-[325px] lg:w-[100%]"
                        image={data.tab4.childImageSharp.gatsbyImageData}
                        alt="Tab4"
                      />
                    </div>
                  </Tab.Panel>
                  <Tab.Panel className="grid grid-cols-1 gap-12 lg:grid-cols-12 p-12">
                    <div className="lg:col-span-9">
                      <div class="border-2 border-cyan w-[8%]"></div>
                      <h2 className="text-greenDark text-2xl font-bold font-quick tracking-tight pt-5">
                        Cultural Activities
                      </h2>
                      <p className="mt-5 font-light text-dark">
                        With cultural activities students get a chance to learn
                        more about various fields such as geography, science,
                        zoology, music, stories etc. We are glad to offer to our
                        students a full set of Cultural activities material such
                        as puzzle maps and folders containing pictures from
                        different countries so they can learn more about
                        different cultures.This will encourage them develop
                        their knowledge about the world around them and also
                        help them to increase their ability for creation and
                        their fine motor skills.
                      </p>
                    </div>
                    <div className="lg:col-span-3">
                      <GatsbyImage
                        className="lg:h-[325px] lg:w-[100%]"
                        image={data.tab5.childImageSharp.gatsbyImageData}
                        alt="Tab5"
                      />
                    </div>
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
