import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  IdentificationIcon,
  PencilIcon,
  CalendarIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
import { graphql, useStaticQuery } from "gatsby";

const Stats = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "stat-bg.jpeg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  const stats = [
    {
      icon: IdentificationIcon,
      number: "50",
      title: "CHILDREN A YEAR",
    },
    {
      icon: PencilIcon,
      number: "150",
      title: "ACTIVITIES A YEAR",
    },
    {
      icon: CalendarIcon,
      number: "18",
      title: "FROM EIGHTEEN MONTHS",
    },
    {
      icon: UserGroupIcon,
      number: "12",
      title: "STAFF",
    },
  ];
  return (
    <div className="max-w-full mx-auto">
      <div className="relative">
        <div className="absolute inset-0">
          <GatsbyImage
            className="h-full w-full object-cover"
            image={data.file.childImageSharp.gatsbyImageData}
            alt="Background"
          />
        </div>
        <div className="max-w-screen-lg m-auto relative px-4 py-12 text-center">
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-y-5 lg:gap-y-8">
            {stats.map((stat) => (
              <div key={stat.title} className="p-5">
                <stat.icon
                  className="h-12 w-12 text-white m-auto stroke-1"
                  aria-hidden="true"
                />
                <div class="mt-3 border border-white w-[10%] m-auto"></div>
                <h4 className="mt-2 font-quick text-4xl text-white">
                  {stat.number}
                </h4>
                <h6 className="mt-1 font-bold font-quick text-xs text-white">
                  {stat.title}
                </h6>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stats;
